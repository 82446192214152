@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --layout-padding: 20px;
  --z-index-fixed: 10;
  --color-primary: #6a6df6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

html,
body {
  height: 100%;
}

div#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0 var(--layout-padding) var(--layout-padding);
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.font-bold {
  font-weight: bold;
}

.font-semi-bold {
  font-weight: 600;
}
