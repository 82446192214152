.common-SplashScreen {
  background-color: white;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }

  &__animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}
